import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import propsFilter from 'shared/ui/helpers/propsFilter';
import symbols, {withSymbol} from 'shared/ui/symbols';

import {TextImage, TombStonedImage, getTextImage} from 'shared/ui/atoms/image';

import calculateSatellitePosition from './helpers/calculateSatellitePosition';
import {defaultAvatar} from './defaultAvatar';

import styles from './styles.scss';

const findClosestValues = (availableSizes, target) => {
  let left = 0;
  let right = availableSizes.length - 1;

  if (target <= availableSizes[left]) {
    return [availableSizes[left], availableSizes[left + 1]];
  }

  if (target >= availableSizes[right]) {
    return [availableSizes[right - 1], availableSizes[right]];
  }

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);

    if (availableSizes[mid] === target) {
      return [availableSizes[mid - 1], availableSizes[mid + 1]];
    }

    if (availableSizes[mid] < target) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }

  // At the end of the loop, left and right will point to the two closest numbers
  return [availableSizes[right], availableSizes[left]];
};

const getSingleActionPosition = (avatarSize, actionSize) => {
  const offsetPerSize = {
    32: {
      top: 6,
      left: 13
    },
    48: {
      top: 6,
      left: 10
    },
    64: {
      top: 2,
      left: 4
    },
    80: {
      top: 1,
      left: 2
    },
    120: {
      top: -8,
      left: 0
    }
  };

  let offset = offsetPerSize[avatarSize];
  if (!offset) {
    const [min, max] = findClosestValues(Object.keys(offsetPerSize), avatarSize);
    const avatarSizeRatio = (avatarSize - min) / (max - min);
    offset = {
      top: offsetPerSize[min].top + (offsetPerSize[max].top - offsetPerSize[min].top) * avatarSizeRatio,
      left: offsetPerSize[min].left + (offsetPerSize[max].left - offsetPerSize[min].left) * avatarSizeRatio
    };
  }

  const {top, left} = offset;
  const actionRatio = (24 - actionSize) / (24 - 16);

  return {
    top: `${-(avatarSize / 2 + top - 4 * actionRatio)}px`,
    left: `${avatarSize / 2 - actionSize + left - 6 * actionRatio}px`
  };
};

const getSingleBadgePosition = (avatarSize, badgeSize) => {
  const offsetPerSize = {
    32: 5,
    48: 2,
    64: 0,
    80: -2,
    120: -10
  };

  let offset = offsetPerSize[avatarSize];
  if (offset === undefined) {
    const [min, max] = findClosestValues(Object.keys(offsetPerSize), avatarSize);
    const avatarSizeRatio = (avatarSize - min) / (max - min);
    offset = offsetPerSize[min] + (offsetPerSize[max] - offsetPerSize[min]) * avatarSizeRatio;
  }

  const badgeRatio = (16 - badgeSize) / (24 - 16);
  const position = avatarSize / 2 - badgeSize + offset - 4 * badgeRatio;

  return {
    top: `${position}px`,
    left: `${position}px`
  };
};

const getPreviewStyles = size => ({
  width: `${size}px`,
  height: `${size}px`
});

const getActionSize = ({size}) => {
  const defaultActionSize = 24;
  return parseInt(size, 10) || defaultActionSize;
};

const getActionStyles = (avatarSize, idx, children, actionSize = 24) => {
  const defaultPosition = getSingleActionPosition(avatarSize, actionSize);

  const position =
    defaultPosition && children.length === 1
      ? defaultPosition
      : calculateSatellitePosition({
          size: actionSize,
          radius: avatarSize / 2,
          index: idx,
          totalSatellites: children.length,
          degreesStart: 0,
          degreesStop: 90
        });
  return {
    width: `${actionSize}px`,
    height: `${actionSize}px`,
    borderRadius: `${actionSize}px`,
    ...position
  };
};

const getBadgeStyles = (avatarSize, idx, children, badgeSize = 16) => {
  const defaultPosition = getSingleBadgePosition(avatarSize, badgeSize);

  const position =
    defaultPosition && children.length === 1
      ? defaultPosition
      : calculateSatellitePosition({
          size: badgeSize,
          radius: avatarSize / 2,
          index: idx,
          totalSatellites: children.length,
          degreesStart: 285,
          degreesStop: 345
        });

  return {
    width: `${badgeSize}px`,
    height: `${badgeSize}px`,
    borderRadius: `${badgeSize}px`,
    ...position
  };
};

class Avatar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.avatarRef = React.createRef();
    this._childLoadedRepos = new WeakMap();
  }

  setDataLoaded = () => {
    window.requestAnimationFrame(() => {
      const avatarRef = this.avatarRef && this.avatarRef.current;
      if (avatarRef) {
        avatarRef.setAttribute('data-loaded', true);
      }
    });
  };

  setDataFailed = () => {
    window.requestAnimationFrame(() => {
      const avatarRef = this.avatarRef && this.avatarRef.current;
      if (avatarRef) {
        avatarRef.setAttribute('data-failed', true);
      }
    });
  };

  getChildHandlers(childMethod, parentMethod) {
    if (typeof childMethod !== 'function') {
      return parentMethod;
    }

    if (this._childLoadedRepos.has(childMethod)) {
      return this._childLoadedRepos.get(childMethod);
    }

    const handler = (...args) => {
      parentMethod();
      childMethod(...args);
    };
    this._childLoadedRepos.set(childMethod, handler);
    return handler;
  }

  getFallback() {
    const {fallback, initials, title, size} = this.props;

    if (fallback) {
      return fallback;
    }

    if (initials) {
      return <TextImage size={size * 2} alt={title} text={initials} seed={initials} />;
    }

    return defaultAvatar;
  }

  getTombstone() {
    const {tombstone, initials, size} = this.props;

    if (tombstone) {
      return tombstone;
    }

    if (initials) {
      return getTextImage({size: size * 2, text: initials, seed: initials});
    }

    return defaultAvatar;
  }

  getPreviewable({title, src, alt}) {
    return (
      <TombStonedImage
        src={src}
        alt={alt}
        title={title}
        fallback={this.getFallback()}
        tombstone={this.getTombstone()}
        handleLoaded={this.setDataLoaded}
        handleFailed={this.setDataFailed}
      />
    );
  }

  render() {
    const {title, url, size, badgeSize, fallback, children, tombstone, initials, outlined, ...props} = this.props;
    const actions = [];
    const badges = [];

    let previewable = this.getPreviewable({title, alt: title, src: url});

    React.Children.forEach(children, child => {
      if (!React.isValidElement(child) || !child.type) {
        return;
      }

      // Child is <AvatarAction> (shared/ui/organisms/avatar/action)
      if (child.type[symbols.Avatar.Action]) {
        if (child.props.size || size >= 48) {
          actions.push(child);
          return;
        }
        actions.push(
          React.cloneElement(child, {
            size: 16
          })
        );
        return;
      }

      // Child is <Icon> (shared/ui/atoms/icon)
      if (child.type[symbols.Icon]) {
        badges.push(child);
        return;
      }

      // Child is <Image> (shared/ui/atoms/image)
      if (symbols.Image.includes(child.type)) {
        previewable = React.cloneElement(child, {
          fallback,
          handleLoaded: this.getChildHandlers(child.props.handleLoaded, this.setDataLoaded),
          handleFailed: this.getChildHandlers(child.props.handleFailed, this.setDataFailed)
        });
        return;
      }

      // Child is <img>
      if (child.type === 'img') {
        previewable = this.getPreviewable({
          title: child.props.title || title,
          alt: child.props.alt || title,
          src: child.props.src
        });
        return;
      }

      previewable = child;
    });

    const containerAttributes = propsFilter(props)
      .dataAttributes()
      .ariaAttributes()
      .styles()
      .like(/(id|role|title|dir|lang|translate|tabIndex|draggable|hidden|inert)/)
      .getFiltered();

    return (
      <div {...containerAttributes} className={clsx(styles['avatar-rounded'], props.className)} ref={this.avatarRef}>
        <div
          style={getPreviewStyles(size)}
          className={clsx({
            [styles.preview]: true,
            [styles.outlined]: outlined
          })}
          data-role="preview"
        >
          {previewable}
        </div>
        <div className={styles.badges}>
          {badges.map((badge, idx) => (
            <div
              key={idx}
              style={getBadgeStyles(size, idx, badges, badgeSize)}
              className={styles.badge}
              data-role="badge"
            >
              {badge}
            </div>
          ))}
        </div>
        <div className={styles.actions}>
          {actions.map((action, idx) => (
            <action.type
              {...action.props}
              className={clsx(styles.action, action.props.className)}
              key={idx}
              style={getActionStyles(size, idx, actions, getActionSize(action.props))}
              size={getActionSize(action.props) / 2}
              data-role="action"
            />
          ))}
        </div>
      </div>
    );
  }
}

Avatar.defaultProps = {
  title: '',
  url: '',
  size: 80
};

Avatar.displayName = 'Avatar';

Avatar.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  size: PropTypes.number,
  outlined: PropTypes.bool,
  badgeSize: PropTypes.oneOf([16, 20, 24]),
  fallback: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  tombstone: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  initials: PropTypes.string
};

export default withSymbol(symbols.Avatar.Base, Avatar);
